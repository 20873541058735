@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  text-align: center;
  margin: auto;
  background-color: #ffffff !important;
}

@font-face {
  font-family: 'Monserrat-Bold';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}
